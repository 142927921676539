import { ChakraTheme, DeepPartial, extendTheme } from "@chakra-ui/react";

export const brandColor = {
  50: "green",
  100: "green",
  200: "green",
  300: "green",
  400: "green",
  500: "green",
  600: "green",
  700: "green",
  800: "green",
  900: "green",
};

export const bg = {
  50: "#FFFFFF",
  100: "#fff8ec",
  200: "#F2E9DE",
  300: "#EFE4D7",
  400: "#ECDFD0",
  500: "#E9DAC9",
  600: "#E6D5C2",
  700: "#E3D0BB",
  800: "#E0CBB4",
  900: "#DFC9AA",
};

export const text = {
  main: "black",
  invert: "white",
  50: "#eceffe",
  100: "#d3d6e1",
  200: "#b8bbc7",
  300: "#9da1af",
  400: "#828897",
  500: "#686e7d",
  600: "#505762",
  700: "#383d47",
  800: "#20232d",
  900: "#060916",
};

export const _theme: DeepPartial<ChakraTheme> = {
  fonts: {
    heading: "Inter Variable, sans-serif",
    body: "Inter Variable, sans-serif",
  },
  colors: {
    brand: brandColor,
    bg,
    text,
  },
};

export const theme = extendTheme(_theme) as ChakraTheme;
