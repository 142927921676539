import * as React from "react";

import "../../styles/global.css";
import { GlobalHead } from "./head";

export interface IRootWrapperProps {}

const GlobalStateCtx = React.createContext<{
  globalState: Record<string, any>;
  setInGlobalState: (id: string, value: (current: any) => any) => void;
}>(null as any);

const TimestampCtx = React.createContext<{
  timestamp: number;
  setTimestamp: (value: (current: number) => number) => void;
}>(null as any);

/** Wraps every page but is not re-mounted when chaning pages */
export function RootWrapper(props: React.PropsWithChildren<IRootWrapperProps>) {
  const [globalState, setGlobalState] = React.useState<Record<string, any>>({});

  const [timestamp, setTimestamp] = React.useState(1500000);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTimestamp((timestamp) => {
        if (timestamp <= 0) {
          clearInterval(timer);
          return 0;
        }

        return timestamp - 1000;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function setInGlobalState(id: string, value: (value: any) => any) {
    setGlobalState((s) => ({ ...globalState, [id]: value(s[id]) }));
  }

  const timestampCtxVal = React.useMemo(
    () => ({
      timestamp,
      setTimestamp,
    }),
    [timestamp]
  );

  const globalStateVal = React.useMemo(() => {
    return { globalState, setInGlobalState };
  }, [globalState]);

  return (
    <TimestampCtx.Provider value={timestampCtxVal}>
      <GlobalStateCtx.Provider value={globalStateVal}>
        <GlobalHead />
        {props.children}
      </GlobalStateCtx.Provider>
    </TimestampCtx.Provider>
  );
}

type StateSetter<T> = T | ((currentState: T) => T);

export function useGlobalState<T>(
  id: string,
  initialValue?: T
): [T, (val: StateSetter<T>) => void] {
  const ctx = React.useContext(GlobalStateCtx);

  // set initial state, hope it's right
  if (!ctx.globalState[id]) {
    ctx.globalState[id] = initialValue;
  }

  function setState(value: StateSetter<T>) {
    if (value instanceof Function) {
      ctx.setInGlobalState(id, (s) => value(s));
      return;
    }

    ctx.setInGlobalState(id, () => value);
  }

  return [ctx.globalState[id], setState];
}

export function useCheckoutState() {
  return useGlobalState("checkout-modal", false);
}

export function useTimestamp() {
  return React.useContext(TimestampCtx);
}
